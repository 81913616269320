import { useState } from "react";
import $ from "jquery";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchUserIn } from "../redux/actions/productActions";
import { API_URL } from "../apis/fakeStoreApi";

export const ModalAddAddressComponent = () => {
  const UserPhone = useSelector((state) => state.phone);

  const dispatch = useDispatch();

  const [AddressInput, setInput] = useState({
    phone: UserPhone.phone,
  });

  const hadleInputChange = (e) => {
    var Value = e.target.value;
    var Id = e.target.id;
    setInput({
      ...AddressInput,
      [Id]: Value,
    });
  };

  const AddressSubmit = (event) => {
    event.preventDefault();
    var formData = new FormData();
    formData.append("postData", JSON.stringify(AddressInput));
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    var url = API_URL + "add_address";
    axios
      .post(url, formData, config)
      .then((response) => {
        if (response.data.response === "updated") {
          localStorage.setItem("user", JSON.stringify(response.data.data));
          dispatch(fetchUserIn(response.data.data));
          $(".modal-backdrop").remove();
          $("#addressModal").removeClass("show");
          $("#addressModal").addClass("hidden");
          $("#addressModal").attr("style", "display: none");
          $("body").removeClass("modal-open");
          $("body").removeAttr("style");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div
        className="modal fade"
        id="addressModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addressModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form className="" onSubmit={AddressSubmit}>
              <div className="modal-header">
                <h5 className="modal-title" id="addressModalLabel">
                  Add Delivery Address
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body pb-0">
                <div className="form-row">
                  <div className="col-md-12 form-group">
                    <label className="form-label">Full Name</label>
                    <div className="input-group">
                      <input
                        placeholder="Full Name"
                        type="text"
                        className="form-control"
                        id="area"
                        onChange={(e) => {
                          hadleInputChange(e);
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12 form-group">
                    <label className="form-label">E-Mail</label>
                    <input
                      placeholder="Enter Email"
                      type="email"
                      className="form-control"
                      id="email"
                      onChange={(e) => {
                        hadleInputChange(e);
                      }}
                      required
                    />
                  </div>
                  <div className="col-md-12 form-group">
                    <label className="form-label">Address</label>
                    <input
                      placeholder="Complete Address e.g. street name, landmark"
                      type="text"
                      className="form-control"
                      id="address_1"
                      onChange={(e) => {
                        hadleInputChange(e);
                      }}
                      required
                    />
                  </div>
                  <div className="col-md-12 form-group">
                    <label className="form-label">Pin</label>
                    <input
                      placeholder="Pincode"
                      type="text"
                      className="form-control"
                      id="pin"
                      maxLength="6"
                      onChange={(e) => {
                        hadleInputChange(e);
                      }}
                    />
                  </div>
                  <div className="col-md-12 form-group">
                    <label className="form-label">
                      City: Currently we are operating in Hyderabad Only<span className="text-danger">*</span>
                    </label>
                    <input
                      placeholder="City"
                      type="text"
                      className="form-control"
                      id="city"
                      onChange={(e) => {
                        hadleInputChange(e);
                      }}
                      value="Hyderabad"
                    />
                  </div>
                  <div className="col-md-12 form-group">
                    <input
                      placeholder="State"
                      type="hidden"
                      className="form-control"
                      id="state"
                      value="Andhra Pradesh"
                    />
                  </div>
                </div>
              </div>
              <div className="p-3">
                <div className="row">
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn btn-outline-danger w-100 p-2"
                      data-dismiss="modal">
                      Close
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      type="submit"
                      className="btn btn-success w-100 p-2" >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
