export const TradeMark = () => {
  return (
    <section className="mb-4" style={{ backgroundColor: "#fff" }}>
      <div className="container">
        <h3 className="mb-2 my-4 mb-md-4 font-weight-bold text-dark text-center">
          Our Trademark
        </h3>
        <div className="row d-flex justify-content-around align-item-end">
          <div className="col-md-4 mb-3">
            <div className="card trade-card h-100 banner-custom-card border-0" style={{ borderRadius: "22px", backgroundColor: "#eaf6fe" }}>
              <div
                className="card-header border-bottom-0 bg-transparent text-center "
              // style={{ backgroundColor: "#F9C6FB" }}
              >
                <h6 className="mt-2 font-weight-bold d-flex justify-content-start align-items-center">
                  <span className="mr-5 section-process1">1</span>
                  <span style={{ color: "#000" }}>90 Min Car Service</span>
                </h6>
              </div>
              <img
                className="card-img-top my-3 mr-auto img-fluid"
                src="./assets/img/car-service.png"
                style={{
                  width: 250,
                  height: 225,
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  //   width: "50%",
                }}
                alt="Card cap"
              />
              <div className="card-body">
                <hr />
                <p className="card-text text-muted">
                  Experience Quick Service which completes in just 90 Minutes,
                  which will help you save your precious time and efforts of
                  visiting Workshops to get your Car Serviced.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card trade-card h-100 banner-custom-card border-0" style={{ borderRadius: "22px", backgroundColor: "#f6e8f1" }}>
              <div className="card-header border-bottom-0 bg-transparent text-center">
                <h6 className="mt-2 font-weight-bold d-flex justify-content-start align-items-center">
                  <span className="section-process2 mr-5">2</span>
                  <span style={{ color: "#000" }}>  In-house Technicians </span>
                </h6>
              </div>
              <img
                className="card-img-top my-3 img-fluid"
                src="./assets/img/inhouseservice.png"
                style={{
                  width: 290,
                  height: 225,
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  //   width: "50%",
                }}
                alt="Card cap"
              />
              <div className="card-body">
                <hr />
                <p className="card-text text-muted">
                  Metre Per Second recruits Best in className German Car Technicians
                  with a Minimum of 8+ years of Experience and Train them. After
                  enabling them with Garage Nation's standards they are assigned
                  for Services/repairs
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card trade-card h-100 banner-custom-card border-0" style={{ borderRadius: "22px", backgroundColor: "#eaf6fe" }}>
              <div className="card-header border-bottom-0 bg-transparent text-center">
                <h6 className="mt-2 font-weight-bold d-flex justify-content-start align-items-center">
                  <span className="section-process3 mr-5">3</span>
                  <span style={{ color: "#000" }}> Savings up to 40% </span>
                </h6>
              </div>
              <img
                className="card-img-top my-3 img-fluid"
                src="./assets/img/savings.png"
                alt="Card cap"
                style={{
                  width: 280,
                  height: 225,
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  //   width: "50%",
                }}
              />
              <div className="card-body">
                <hr />
                <p className="card-text text-muted">
                  Metre Per Second provides 100% Transparent Service which means
                  all Parts are opened right in front of you while performing
                  service and no more surprised or extra spares or costs
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
