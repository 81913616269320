import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { API_URL } from "../apis/fakeStoreApi";

export const UserOrders = () => {
  const user = useSelector((state) => state.user.payload);
  const history = useHistory();
  if (user !== undefined && !user[0].id) {
    history.push("/");
  }
  const [OrdersData, setOrdersData] = useState([]);
  useEffect(() => {
    var formData = new FormData();
    formData.append("postData", JSON.stringify(user));
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    var url = API_URL + "user_orders";
    axios
      .post(url, formData, config)
      .then((response) => {
        if (response.data.response === "valid") {
          setOrdersData(response.data.data);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  }, [user]);

  const RenderOrders = OrdersData.map((Item) => {
    return (
      <>
        <div className="my-3 trade-card" key={Item.order_id} style={{ backgroundColor: "#fff", borderRadius: "15px", boxShadow: "0 0px 30px rgb(0 0 0 / 5%)" }}>
          <Link to="#" className="text-decoration-none text-dark">
            <div className="p-3 rounded">
              <div className="d-flex align-items-center mb-4">
                {
                  Item.status === "placed" ?
                    <button className="btn btn-success text-capitalize text-white py-1 px-2 mb-0 rounded small">
                      {Item.status}
                    </button>
                    :
                    <button className="btn btn-outline-danger text-capitalize py-1 px-2 mb-0 rounded small">
                      {Item.status}
                    </button>
                }

                <p className="ml-auto small mb-0">
                  <strong>Date</strong>: {Item.slot_date}
                  <br />
                  <strong>Time</strong>: {Item.slot_time}
                </p>
              </div>
              <div className="d-md-flex">
                <p className="text-muted m-0 mb-3 mb-md-0 mb-lg-0">
                  <span className="font-weight-bold">  Transaction. ID </span>
                  <br />
                  <span className="text-dark">
                    MPSH0025{('0000' + Item.order_id).substr(-4)}
                  </span>
                </p>
                <p className="text-muted m-0 ml-auto mb-3 mb-md-0 mb-lg-0">
                  <span className="font-weight-bold"> Pick Up at </span>
                  <br />
                  <span className="text-dark">
                    {Item.at_garage === "1" ? (
                      <>At Garage</>
                    ) : (
                      <>{Item.address}</>
                    )}
                  </span>
                </p>
                <p className="text-muted m-0 ml-auto mb-3 mb-md-0 mb-lg-0">
                  <span className="font-weight-bold">   Total Payment </span>
                  <br />
                  <span className="text-dark">
                    {Item.total_price}
                  </span>
                </p>
              </div>
            </div>
          </Link>
        </div>
      </>
    );
  });
  return (
    <>
      <section className="" style={{ overflowX: "hidden" }}>
        <div className="container">
          <h5 className="container ml-5 pl-5 mb-0 mt-3">Requests</h5>
          <div className="row justify-content-around">
            <div className="tab-content col-md-10" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="completed"
                role="tabpanel"
                aria-labelledby="completed-tab">
                <div className="order-body text-center">
                  {OrdersData.length === 0 ? (
                    <div className="w-100 text-center">
                      <img src="./assets/nodata.svg" className="img-fluid w-50" />
                      <h3>
                        No data found
                      </h3>
                    </div>
                  ) : (
                    RenderOrders
                  )}
                </div>
                {OrdersData.length < 5 ? (
                  <div style={{ marginBottom: 70 }}></div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
