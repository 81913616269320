import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

export const AddressComponent = (props) => {
  const user = useSelector((state) => state.user.payload);
  const show = Array.isArray(user);
  let ShowAdd = "";
  if (user) {
    ShowAdd = show ? user[0].address_1 : user.address_1;
  }
  const [WalkIn, setWalkIn] = useState(true);
  useEffect(() => {
    if (ShowAdd && ShowAdd !== "") {
      props.onAddressSet(true);
    } else {
      props.onAddressSet(false);
    }
    if (props.ServiceTypeOption === "walkin") {
      setWalkIn(false);
    } else {
      setWalkIn(true);
    }
  }, [ShowAdd, props]);

  return (
    <>
      <div className="shadow-sm bg-white overflow-hidden mt-1 mt-md-0 mt-lg-0">
        <div className="card-header bg-white border-0 p-0" id="headingtwo">
          <h2 className="mb-0">
            <button
              className="btn d-flex align-items-center bg-white btn-block text-left btn-lg h5 px-3 py-4 m-0"
              type="button"
            >
              <span className="c-number">3</span> Pick Up Address{" "}
              <div
                to="#"
                data-toggle="modal"
                data-target="#addressModal"

                className="text-decoration-none text-success ml-auto">
                <i className="icofont-plus-circle mr-1"></i> {ShowAdd ? 'Change Pick Up Address' : 'Add Pick Up Address'}
              </div>
            </button>
          </h2>
        </div>
        <div>
          <div className="card-body p-0 border-top">
            <div className="osahan-order_address">
              <div className="mx-4 my-3">
                {/* {RenderAddress} */}
                <div className="custom-control col-lg-12 col-md-12 custom-radio mb-3 position-relative pr-0 border-custom-radio">
                  <input
                    type="radio"
                    id="customRadioInline1"
                    name="customRadioInline1"
                    className="custom-control-input"
                    defaultChecked
                  />
                  {WalkIn ? (
                    <>
                      {ShowAdd ? (
                        <label
                          className="custom-control-label w-100"
                          htmlFor="customRadioInline1">
                          <div>
                            <div className="p-3 bg-white rounded shadow-sm w-100">
                              <div className="d-flex align-items-center mb-2">
                                <p className="mb-0 h6">
                                  {show ? user[0].firstname : user.firstname}
                                </p>
                                <p className="mb-0 badge badge-success ml-auto"></p>
                              </div>
                              <p className="small text-muted m-0">
                                {show ? user[0].address_1 : user.address_1},{" "}
                              </p>
                              <p className="small text-muted m-0">
                                {show ? user[0].city : user.city},{" "}
                                {show ? user[0].state : user.state},<br />
                                {show ? user[0].address_2 : user.address_2}.
                              </p>
                              {/* <p className="pt-2 m-0 text-right">
                                <span className="small">
                                  <Link
                                    to="#"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    className="text-decoration-none text-info">
                                    Edit
                                  </Link>
                                </span>
                              </p> */}
                            </div>
                            <span className="btn btn-light border-top btn-lg btn-block">
                              Pick Up Here
                            </span>
                          </div>
                        </label>
                      ) : null}{" "}
                    </>
                  ) : (
                    <label
                      className="custom-control-label w-100"
                      htmlFor="customRadioInline1">
                      <div>
                        <div className="p-3 bg-white rounded shadow-sm w-100">
                          <div className="d-flex align-items-center mb-2">
                            <p className="mb-0 h6">Metre Per Second - Workshop</p>
                            <p className="mb-0 badge badge-success ml-auto"></p>
                          </div>
                          <p className="small text-muted m-0">
                            7-2-1852/1/A Opp to Kalpataru Residency Near
                            <br />
                            Erragadda Metro Station, Pillar No. 933,
                          </p>
                          <p className="small text-muted m-0">
                            Hyderabad, Telangana 500018
                          </p>
                        </div>
                        <span className="btn btn-light border-top btn-lg btn-block">
                          Drive to Here
                        </span>
                      </div>
                    </label>
                  )}
                </div>
                {ShowAdd ? (
                  <div className="d-flex justify-content-end w-100">
                    {/* <Link
                      to="#"
                      className="btn btn-success"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapsethree"
                      aria-expanded="true"
                      aria-controls="collapsethree">
                      Continue
                    </Link> */}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
