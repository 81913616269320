export const RefundPolicy = () => {
    return (
        <section className="mt-5">
            <div className="container mb-5">
                <div className="row d-flex justify-content-center mb-3">
                    <h2>
                        Cancellation and Refund Policy
                    </h2>
                </div>
                <div className="row d-flex">
                    <div className="col-sm-12">

                        <h5>
                            Cancellation Model: The Orders are cancelled by two means:
                        </h5>


                        <p>

                            An Order which is cancelled by the customers
                        </p>
                        <p>

                            An Order cancelled by the operations team upon customer’s approval.
                        </p>
                        <p>

                            A refund will be initiated and approved if the customer pre-pays and cancels the Order. In case of agent induced cancellation also a refund request is generated and approved upon verification.
                        </p>

                        <p>

                            Refund Model is only applicable for the B2C customers through the same medium in which they had completed their transactions.
                        </p>

                        <h4>
                            METRE PER SECOND
                        </h4>
                    </div>
                </div>
            </div>
        </section>
    );
}