import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { API_URL } from "../apis/fakeStoreApi";
import { removeCart } from "../redux/actions/productActions";

export const CartPageItems = () => {

  const CartItems = useSelector((state) => state.cartItems.cartProducts);
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(CartItems));
    var url = API_URL + "cart_items";
    var formData = new FormData();
    formData.append("CartItems", JSON.stringify(CartItems));
    if (localStorage.getItem("user_phone")) {
      formData.append("user", localStorage.getItem("user_phone"));
    }

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios.post(url, formData, config)
  }, [CartItems]);


  const removeItemList = (Item) => {
    dispatch(removeCart(Item));
    localStorage.removeItem("cartItems");
  };
  // let total_pay = 0;
  let total_count = 0;

  const renderCartItems = CartItems?.map((Item) => {
    const {
      product_id,
      product_name,
      product_price,
      product_discount,
      product_img,
    } = Item;
    // total_pay += parseInt(
    //   parseInt(product_price) -
    //   (parseInt(product_discount) * parseInt(product_price)) / 100
    // );
    // total_discount += 200;
    total_count += 1;

    // const OpenPopUp = () => {
    //   $("#verificationModal").addClass("show");
    //   $("#verificationModal").attr("style", "display: block");
    //   $("body").append("<div className='modal-backdrop fade show'></div>");
    // };

    return (
      <div
        key={product_id}
        className="cart-items bg-white position-relative border-bottom">
        <div className="position-absolute">
          <span className="badge badge-danger m-1">{product_discount}%</span>
        </div>
        <div className="d-flex  align-items-center p-3">
          <img src={product_img} className="img-fluid" alt="Not Found" />
          <div className="ml-3 text-dark text-decoration-none w-100">
            <p className="mb-1">{product_name}</p>
            {/* <p className="text-muted mb-2">
              <small>{product_description}</small>
            </p> */}
            <div className="d-flex align-items-center">
              <p className="total_price font-weight-bold m-0">
                ₹
                {parseInt(
                  parseInt(product_price) -
                  (parseInt(product_discount) * parseInt(product_price)) / 100
                )}
              </p>
              <div
                className="d-flex ml-auto btn btn-danger"
                onClick={() => removeItemList(Item)}>
                Remove
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
  return (
    <>
      <div className="border-0 shadow-sm overflow-hidden">
        <div className="card-header bg-white border-0 p-0" id="headingOne">
          <h2 className="mb-0 d-flex justify-content-between align-items-center">
            <button
              className="btn d-flex align-items-center bg-white btn-block text-left btn-lg h5 px-3 py-4 m-0"
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne">
              <span className="c-number">1</span> Cart ({total_count} items)
            </button>
            {/* <i className="fas fa-chevron-down mr-4" style={{ fontSize: "15px" }}></i> */}
          </h2>
        </div>
        <div
          id="collapseOne"
          className="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordionExample">
          <div className="card-body p-0 border-top">
            <div className="osahan-cart">
              <div style={{ maxHeight: 500, overflowY: "scroll" }}>
                {renderCartItems}
              </div>
              {/* {
                localStorage.getItem("user_phone") && localStorage.getItem("user")
                  ?
                  <div className="d-flex justify-content-end">
                    <Link
                      to="#"
                      className="text-decoration-none p-3"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                      aria-expanded="true"
                      aria-controls="collapseFour">
                      <div className="rounded p-2 btn-success">
                        <div className="more d-flex justify-content-center">
                          <p className="small m-0 text-white text-center" style={{ fontSize: "13px" }}>
                            Continue
                            <i className="text-white icofont-simple-right"></i>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  :

                  <div
                    data-toggle="modal"
                    data-target="#verificationModal"
                  >
                    <Link
                      to="#"
                      data-toggle="modal"
                      data-target="#verificationModal"

                      className="text-decoration-none btn btn-block p-3">
                      <div className="rounded p-3 btn-success">
                        <div className="more d-flex justify-content-between">
                          <p className="m-0 text-left text-white" style={{ fontSize: "15px" }}>Subtotal
                            <span style={{ fontSize: "16px", fontWeight: "600", marginLeft: "10px", color: "#fff" }}> ₹{total_pay}</span></p>
                          <p className="small m-0 text-white" style={{ fontSize: "15px" }}>Checkout <i className="text-white icofont-simple-right"></i></p>
                        </div>
                      </div>
                    </Link>
                  </div>
              } */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
