import fakestoreapi from "../../apis/fakeStoreApi";
import { ActionTypes } from "../contants/action-types";

export const fetchProducts = () => async (dispatch) => {
  const response = await fakestoreapi.get("/products");
  dispatch({ type: ActionTypes.FETCH_PRODUCTS, payload: response.data });

  // const data = { key: "3bn7nkjc9jkd9" };
  // axios.post("http://localhost/mps/products", data).then((response) => {
  //   //console.log(response, "Response Fetch");
  //   dispatch({ type: ActionTypes.FETCH_PRODUCTS, payload: response.data });
  // });
};

export const fetchProduct = () => async (dispatch) => {
  const response = await fakestoreapi.get(`/products_category`);
  dispatch({ type: ActionTypes.SELECTED_CAR, payload: response.data });
  // const data = { key: "3bn7nkjc9jkd9" };
  // fakestoreapi
  //   .post("/products_category", data)
  //   .then((response) =>
  //     dispatch({ type: ActionTypes.SELECTED_CAR, payload: response.data })
  //   );
};

export const fetchProductList = (id, carId) => async (dispatch) => {
  const response = await fakestoreapi.get(`/products_category/${id}?carId=${carId}`);
  dispatch({ type: ActionTypes.SELECTED_CATEGORY, payload: response.data });
  // const data = { key: "3bn7nkjc9jkd9" };
  // fakestoreapi
  //   .post("/products_category/${id}", data)
  //   .then((response) =>
  //     dispatch({ type: ActionTypes.SELECTED_CATEGORY, payload: response.data })
  //   );
};

export const fetchCarDetails = () => async (dispatch) => {
  const response = await fakestoreapi.get(`/car_details`);
  dispatch({ type: ActionTypes.FETCH_CAR_DATA, payload: response.data });
  // const data = { key: "3bn7nkjc9jkd9" };
  // fakestoreapi
  //   .post("/car_details")
  //   .then((response) =>
  //     dispatch({ type: ActionTypes.FETCH_CAR_DATA, payload: response.data })
  //   );
};

export const fetchUserIn = (data) => async (dispatch) => {
  dispatch({ type: ActionTypes.USER_IN, payload: data });
};

export const fetchCars = (data) => async (dispatch) => {
  // const response = await fakestoreapi.get(`/cara`);
  dispatch({ type: ActionTypes.SELECTED_CAR, payload: data });
};

export const fetchLocalCar = (data) => async (dispatch) => {
  // const response = await fakestoreapi.get(`/cara`);
  dispatch({ type: ActionTypes.LOCAL_SELECTED_CAR, payload: data });
};

export const fetchLocalCart = (data) => (dispatch) => {
  dispatch({ type: ActionTypes.LOCAL_CART_UPDATE, payload: data });
};

export const setProducts = (products) => {
  return {
    type: ActionTypes.SET_PRODUCTS,
    payload: products,
  };
};

export const selectedProduct = (product) => {
  return {
    type: ActionTypes.SELECTED_PRODUCT,
    payload: product,
  };
};

export const selectedCar = (product) => {
  return {
    type: ActionTypes.SELECTED_CAR,
    payload: product,
  };
};

export const removeSelectedProduct = () => {
  return {
    type: ActionTypes.REMOVE_SELECTED_PRODUCT,
  };
};

export const localCartUpdate = (cartItems) => {
  return {
    type: ActionTypes.LOCAL_CART_UPDATE,
    payload: cartItems,
  };
};

export const addCart = (product) => {
  return {
    type: ActionTypes.ADD_CART,
    payload: product,
  };
};

export const removeCart = (product) => {
  return {
    type: ActionTypes.REMOVE_CART,
    payload: product,
  };
};

export const EmptyCart = () => {
  return {
    type: ActionTypes.EMPTY_CART,
  };
};

export const UserOut = () => {
  return {
    type: ActionTypes.USER_OUT,
  };
};

export const UserNo = (phone) => {
  return {
    type: ActionTypes.USER_NO,
    payload: phone,
  };
};

export const LocalUserNo = (phone) => {
  return {
    type: ActionTypes.LOCAL_USER_NO,
    payload: phone,
  };
};
