// import { PopUp } from "../containers/pop-up";
// import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./layouts.styles.css";
// import { Link } from "react-router-dom";

export const LandingPageHeader = () => {
  const carSelected = useSelector((state) => state.carSelected.carSelected);
  return (
    <section
      className="header-container" style={{ overflowX: "hidden" }}>
      <div
        className="row d-flex justify-content-center align-items-center">
        <div className="col-md-8 mb-2 mb-md-0 mb-lg-0">
          <img
            className="img-fluid rounded"
            style={{ width: "100%" }}
            src="./assets/img/bg.jpg"
            alt=""
          />
          {/* <h1>
              <strong>Metre Per Second </strong>
            </h1>
            <h4 className="text-secondary mb-3">
              Car Repair at your Doorstep <br />
            </h4>
            <h5 className="text-secondary mb-3">
              <i className="far fa-star" style={{ fontSize: 17 }}></i> 4.8 Rated
              <br />
            </h5>
            <h6 className="text-secondary" style={{ lineHeight: 1.5 }}>
              Premium Car Service & Repair <br />
              Free Pickup & Drop for Car Repairs
              <br />
              Car Service performed right @ Doorstep
            </h6> */}
        </div>
        {/* <div className="card col-md-5 sticky-sidebar position-relative">
            <div className="card-body">
              <h5 className="card-title text-left">Pick a Car</h5>
              <p className="card-subtitle text-muted text-left mb-3">
                For Tailored Services
              </p>
              <div className="card-text">
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-10 form-group">
                    <div
                      className="form-control form-control-lg"
                      data-toggle="modal"
                      data-target="#SelectCar"
                      placeholder="Select a Car">
                      {" "}
                      {`${
                        carSelected.Company
                          ? carSelected.Company + ", " + carSelected.Model
                          : "Select a Car"
                      }`}
                    </div>
                  </div>
                  <div className="col-sm-12 form-group ">
                    <Link
                      to="/product_list"
                      type="submit"
                      className="card-link btn btn-outline-warning float-right mr-5">
                      Go To Services
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        <div className="rounded col-md-4 sticky-sidebar position-relative">
          <div className="m-3" style={{ backgroundColor: "#f6e8f1", borderRadius: "15px" }}>
            <div className="card-body">
              <h3 className="mb-0 font-weight-bold" style={{ fontSize: '25px' }}>
                Metre Per Second
              </h3 >
              <h4 className="text-secondary mb-3" style={{ fontSize: '1.0rem' }}>
                Car Repair at your Doorstep <br />
              </h4>

              {/* <h5 className="card-title text-left">Pick a Car</h5>
              <p className="card-subtitle text-muted text-left mb-3">
                For Tailored Services
              </p> */}
              <div className="card-text">
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-12 form-group">
                    <div
                      style={{ fontSize: "15px" }}
                      className="form-control form-control-lg car-block"
                      data-toggle="modal"
                      data-target="#SelectCar"
                      placeholder="Select a Car">
                      {" "}
                      {`${carSelected.Company
                        ? carSelected.Company + ", " + carSelected.Model
                        : "Select a Car"
                        }`}
                    </div>
                  </div>
                  <div className="col-sm-12 form-group d-flex justify-content-center">
                    <Link
                      to="/product_list"
                      type="submit"
                      className="get_started btn float-right" style={{ fontSize: "14px", backgroundColor: "#ffbf00" }}>
                      Get Started <i className="fas fa-arrow-right" style={{ marginLeft: "4px" }}></i>
                    </Link>
                  </div>
                </div>
              </div>

              <h6 className="text-secondary" style={{ lineHeight: 1.5 }}>
                <ul className="list-unstyled">
                  <li className="mb-2 ">
                    <div className="d-flex align-items-center">
                      <i className="fas fa-check icons-bg"></i>
                      <span className="ml-1" style={{ fontSize: "13px" }}>  Premium Car Service & Repair </span>
                    </div>
                  </li>

                  <li className="mb-2">
                    <i className="fas fa-check icons-bg"></i>
                    <span className="ml-1" style={{ fontSize: "13px" }}>1 Year /12,000 kms Service Warranty</span>
                  </li>
                  <li className="mb-2">
                    <i className="fas fa-check icons-bg"></i>
                    <span className="ml-1" style={{ fontSize: "13px" }}>4.8 Rated Car Maintenance Company</span>
                  </li>
                  <li className="mb-2">
                    <i className="fas fa-check icons-bg"></i>
                    <span className="ml-1" style={{ fontSize: "13px" }}>Car Service Performed right at Your Place</span>
                  </li>
                  <li className="mb-2">
                    <i className="fas fa-check icons-bg"></i>
                    <span className="ml-1" style={{ fontSize: "13px" }}>Free Pick Up and Drop for Car Major Repairs</span>
                  </li>
                  <li className="mb-2">
                    <div className="d-flex align-items-center">
                      <i className="fas fa-check icons-bg"></i>
                      <span className="ml-1" style={{ fontSize: "13px" }}>  Car Services are powered by Synthetic Engine Oil</span>
                    </div>
                  </li>
                </ul>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
