export const Stats = () => {
  return (
    // <section>
    //   <div className="container py-5">
    //     <div className="row d-flex justify-content-around">
    //       <div className="col-md-4 text-center">
    //         <img
    //           src="./assets/img/repair.png"
    //           alt="not found"
    //           style={{ height: 75 }}
    //         />
    //         <h3>150K+</h3>
    //         <p>Car get Serviced</p>
    //       </div>
    //       <div className="col-md-4 text-center">
    //         <img
    //           src="./assets/img/chat.png"
    //           alt="not found"
    //           style={{ height: 75 }}
    //         />

    //         <h3>100K+</h3>
    //         <p>Happy Customers</p>
    //       </div>
    //       <div className="col-md-4 text-center">
    //         <img
    //           src="./assets/img/stars.png"
    //           alt="not found"
    //           style={{ height: 75 }}
    //         />
    //         <h3>4.8</h3>
    //         <p>Rated</p>
    //       </div>
    //     </div>
    //   </div>
    // </section>

    <section className="stats-className" style={{ backgroundImage: 'url("./assets/img/car/08.png")' }}>
      <div className="container px-0">
        <div className="row py-3 mx-1 mx-md-0 mx-lg-0">
          <div className="col-sm-12 col-md-7 col-lg-7">
            <h3 className="font-weight-bold text-dark ">What we have achieved till date</h3>
            <p className="text-italic col-6">Metre Per Secod is a 5 year old India's Premium and Best Rated Car Maintenance Startup. A glimpse of what we have achieved is as follows</p>
          </div>
          <div className="col-sm-12 col-md-5 col-lg-5">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                <div className="card card-inner-box p-3">
                  <div className="d-flex justify-content-between">
                    <div className="icons-background">
                      <i className="fas fa-car pl-3"></i>
                    </div>
                    <div>
                      <h3 className="text-center">25000+</h3>
                      <p className="pb-0">Car get serviced</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                <div className="card card-inner-box p-3">
                  <div className="d-flex justify-content-between">
                    <div className="icons-background">
                      <i className="far fa-smile pl-3"></i>
                    </div>
                    <div>
                      <h3 className="text-center">99%</h3>
                      <p className="pb-0">Happy Customers</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                <div className="card card-inner-box p-3">
                  <div className="d-flex justify-content-between">
                    <div className="icons-background">
                      <i className="far fa-star pl-3"></i>
                    </div>
                    <div>
                      <h3 className="text-center">4.8</h3>
                      <p className="pb-0">Rated</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="card card-inner-box p-3">
                  <div className="d-flex justify-content-between">
                    <div className="icons-background">
                      <i className="fab fa-servicestack pl-3"></i>
                    </div>
                    <div>
                      <h3 className="text-center">150+</h3>
                      <p className="pb-0">Touch india points</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
