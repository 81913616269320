export const ContactUs = () => {
  return (
    <section style={{ backgroundColor: "#fff" }}>
      <div className="container mb-4">
        <h3 className="font-weight-bold my-4 mb-0 text-dark text-left">
          Contact us
        </h3>
        {/* <h5 className="text-center mb-4 text-danger">CONTACT US</h5> */}
        <div className="row align-items-center">
          <div className="col-sm-12 col-lg-7 col-md-7">
            <h4 className="mb-3" style={{}}>
              If it is something about Cars, let's talk
            </h4>
            <h5 className="mb-3">
              Metre Per Second - Car Service & Car Repair
            </h5>
            <h6 className="mb-4">
              3rd Floor, T-hub Building, IIIT Campus, Gachibowli, Hyderabad
            </h6>
            <a href="tel:918555824915">
              <h6 className="text-danger">
                <span>855-582-4915 </span>
              </h6>
            </a>
            <a href="mailto:support@metrepersecond.com">
              <h6 className="text-danger">
                <span>support@metrepersecond.com</span>
              </h6>
            </a>
            <div className="m-auto">
              <a href="https://www.facebook.com/metrepersecond" target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a>
              <a href="https://www.instagram.com/metrepersecond_india/" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a>
              <a href="https://www.youtube.com/channel/UCxJq0hULZKQX8aORSMxZ6Ng" target="_blank" rel="noreferrer"><i className="fa fa-youtube" ></i></a>
              <a href="https://api.whatsapp.com/send?phone=918555824915" target="_blank" rel="noreferrer"><i className="fa fa-whatsapp"></i></a>
              <a href="https://in.linkedin.com/company/metre-per-second" target="_blank" rel="noreferrer"><i className="fa fa-linkedin"></i></a>
            </div>
          </div>
          <div className="col-sm-12 col-lg-5 col-md-5 text-center">
            <img
              className="img-fluid"
              src="./assets/img/contact-us.png"
              alt=""
              style={{ height: 250 }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
