import { ActionTypes } from "../contants/action-types";

const initialState = {
  products: [],
};

const initialCart = {
  cartProducts: [],
};

const initialStateProduct = {
  product: 1,
};
const initialCarData = {
  carData: [],
};
const initialStateProductCategory = {
  productCategoryList: [],
};

const initialStateCar = {
  carSelected: {
    isYear: false,
    Year: "",
    isCompany: false,
    Company: "",
    isModel: false,
    Model: "",
    isFuel: false,
    Fuel: "",
    CarSelected: "",
    isCarSelected: false,
    CarImage: "",
  },
};

export const productReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_PRODUCTS:
      return { ...state, products: payload };
    case ActionTypes.FETCH_PRODUCTS:
      return { ...state, products: payload };
    default:
      return state;
  }
};

export const userReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ActionTypes.USER_IN:
      return { ...state, payload };
    case ActionTypes.USER_OUT:
      return { ...state, payload: undefined };
    default:
      return state;
  }
};

export const selectedProductReducer = (
  state = initialStateProduct,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SELECTED_PRODUCT:
      return { ...state, product: payload };
    case ActionTypes.REMOVE_SELECTED_PRODUCT:
      return {};
    default:
      return state;
  }
};

export const cartItemsReducer = (state = initialCart, { type, payload }) => {
  switch (type) {
    case ActionTypes.ADD_CART:
      if (state.cartProducts === null) {
        return { ...state, cartProducts: [payload] };
      } else {
        return { ...state, cartProducts: [...state.cartProducts, payload] };
      }
    case ActionTypes.REMOVE_CART:
      let NewCart = state.cartProducts.filter(
        (Item) => Item.product_id !== payload.product_id
      );
      return { ...state, cartProducts: NewCart };
    case ActionTypes.LOCAL_CART_UPDATE:
      return { ...state, cartProducts: payload };
    case ActionTypes.EMPTY_CART:
      return { ...state, cartProducts: null };
    default:
      return state;
  }
};

export const selectedProductCategory = (
  state = initialStateProductCategory,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SELECTED_CATEGORY:
      return { ...state, productCategoryList: payload };
    default:
      return state;
  }
};

export const carDataReducer = (state = initialCarData, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_CAR_DATA:
      return { ...state, carData: payload };
    default:
      return state;
  }
};

export const selectedCarReducer = (
  state = initialStateCar,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SELECTED_CAR:
      const {
        CarSelected,
        Company,
        Fuel,
        Model,
        Year,
        isCarSelected,
        isCompany,
        isFuel,
        isModel,
        isYear,
        CarImage,
      } = payload;

      return {
        ...state,
        carSelected: {
          ...state.carSelected,
          isCompany: isCompany,
          Company: Company,
          isModel: isModel,
          Model: Model,
          isFuel: isFuel,
          Fuel: Fuel,
          isYear: isYear,
          Year: Year,
          isCarSelected: isCarSelected,
          CarSelected: CarSelected,
          CarImage: CarImage,
        },
      };
    case ActionTypes.LOCAL_SELECTED_CAR:
      return { ...state, carSelected: payload };
    default:
      return state;
  }
};

const initialPhone = {
  phone: "",
  isVerified: false,
};

export const UserNo = (state = initialPhone, { type, payload }) => {
  switch (type) {
    case ActionTypes.USER_NO:
      return { ...state, phone: payload.phone, isVerified: payload.isVerified };
    case ActionTypes.LOCAL_USER_NO:
      const data = JSON.parse(payload, true);
      return { ...state, phone: data.phone, isVerified: data.isVerified };
    default:
      return state;
  }
};
