export const AboutUs = () => {
  return (
    <section className="pb-4 bg-light">
      <div className="container">
        <h3 className="font-weight-bold text-dark text-left py-4 mb-0">
          About us
        </h3>
        <div className="row">
          <div className="col-sm-12 col-md-4 col-lg-4 mb-3 mb-md-0 mb-lg-0">
            <div className="card-inner-box h-100 trade-card">
              <div className="icon icon-engine-2">
                <div className="text-center">
                  <img
                    className="img-fluid w-75 mb-3"
                    src="./assets/img/inception1.png"
                    alt="not found"
                  // style={{ height: 250 }}
                  />
                </div>
              </div>
              <b className="mb-3" style={{ color: "#ffbf00" }}>INCEPTION</b>
              <p className="text-muted">
                Established in 2016, with a Vision to Provide Customers
                Transparent, Convenient and Reliable Car Service and Repair right
                at Customer Doorstep in Hyderabad
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4 mb-3 mb-md-0 mb-lg-0">
            <div className="card-inner-box h-100 trade-card">
              <div className="icon icon-engine-2">
                <div className="text-center">
                  <img
                    width="68%"
                    className="img-fluid mb-3"
                    src="./assets/img/car_service.png"
                    alt="not found"
                  // style={{ height: 250 }}
                  />
                </div>
              </div>
              <b className="mb-3" style={{ color: "#ffbf00" }}>FIGHT CLUB</b>
              <p className="text-muted">
                Car owners often lack visibility regarding the quality of
                Mechanics, usage of Spare parts and Transparency in price. At the
                same time, the Mechanics who put in the hard work fixing cars make
                very little money at repair shops (less than 20% of what consumers
                pay), and rarely get the Recognition they deserve.
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4 mb-3 mb-md-0 mb-lg-0">
            <div className="card-inner-box h-100 trade-card">
              <div className="icon icon-engine-2">
                <div className="text-center">
                  <img
                    className="img-fluid w-75 mb-3"
                    src="./assets/img/pursuit.png"
                    alt="not found"
                  // style={{ height: 250 }}
                  />
                </div>
              </div>
              <b className="mb-3" style={{ color: "#ffbf00" }}>PURSUIT OF HAPPINESS</b>
              <p className="text-muted">
                Now, Metre Per Second is a Premier Doorstep Car Care Company
                providing Car Service, Repair and Detailing Services with
                Professional Technicians right at your location in Hyderabad.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
