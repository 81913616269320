export const ActionTypes = {
  FETCH_PRODUCTS: "FETCH_PRODUCTS",
  SET_PRODUCTS: "SET_PRODUCTS",
  SELECTED_PRODUCT: "SELECTED_PRODUCT",
  REMOVE_SELECTED_PRODUCT: "REMOVE_SELECTED_PRODUCT",
  SELECTED_CAR: "SELECTED_CAR",
  SELECTED_CATEGORY: "SELECTED_CATEGORY",
  ADD_CART: "ADD_CART",
  REMOVE_CART: "REMOVE_CART",
  LOCAL_CART_UPDATE: "LOCAL_CART_UPDATE",
  LOCAL_SELECTED_CAR: "LOCAL_SELECTED_CAR",
  FETCH_CAR_DATA: "FETCH_CAR_DATA",
  USER_IN: "USER_IN",
  USER_OUT: "USER_OUT",
  EMPTY_CART: "EMPTY_CART",
  USER_NO: "USER_NO",
  LOCAL_USER_NO: "LOCAL_USER_NO",
};
