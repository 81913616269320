import { useSelector } from "react-redux";

export const SideTotalBill = (props) => {
  const CartItems = useSelector((state) => state.cartItems.cartProducts);

  // const deliveryCharges = props.deliveryCharges;
  let total_pay = 0;
  let total_discount = 0;
  let total_count = 0;

  CartItems?.map((Item) => {
    const { product_price, product_discount } = Item;
    total_discount += parseInt(
      (parseInt(product_discount) * parseInt(product_price)) / 100
    );
    total_pay += parseInt(
      parseInt(product_price) -
      (parseInt(product_discount) * parseInt(product_price)) / 100
    );
    total_count += 1;
    return null;
  });

  return (
    <>
      <div className="cart-bg-color">
        <div className="rounded overflow-hidden sham mb-md-3 mb-lg-3 checkout-sidebar">
          <div className="d-flex align-items-center osahan-cart-item-profile p-1 sdsdd">
            <img
              alt="MPS Logo"
              src="assets/img/mps_logo.jpg"
              className="mr-3 rounded-circle img-fluid"
            />
            <div className="d-flex flex-column">
              <h6 className="mb-1" style={{ fontSize:"12px"}}>Metre Per Second - Hyderabad</h6>
              {/* <p className="mb-0 small text-muted">
                <i className="feather-map-pin"></i> Hyderabad
              </p> */}
            </div>
          </div>
          <div>
            <div className="p-3 clearfix">
              <p className="font-weight-bold small mb-2">Bill Details</p>
              <p className="mb-1">
                Item Total{" "}
                <span className="small text-muted">({total_count} item)</span>{" "}
                <span className="float-right text-dark">₹{total_pay + total_discount}</span>
              </p>
              <p className="mb-3">
                Delivery Fee{" "}
                <span
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Delivery partner fee - ₹3"
                  className="text-info ml-1">
                  <i className="icofont-info-circle"></i>
                </span>
                <span className="float-right text-dark">
                  {/* ₹{deliveryCharges} */}
                  {/* <strike>
                    <small className="mr-2">
                      {deliveryCharges !== 0 ? '₹'+deliveryCharges: null}
                    </small>
                  </strike> */}
                  ₹0
                </span>
              </p>

              <h6 className="mb-0 text-success">
                Total Discount
                <span className="float-right text-success">
                  ₹{total_discount}
                </span>
              </h6>
              <small >Coupon Applied <b className="text-danger">METREPERSECOND</b> </small>
            </div>
            <div className="p-3 border-top">
              <h6 className="mb-0">
                TO PAY{" "}
                <span className="float-right text-success font-weight-bold">
                  ₹{total_pay}
                  {/* ₹{total_pay + deliveryCharges} */}
                </span>
              </h6>
            </div>
          </div>
        </div>
        <p className="text-dark py-2 text-center border rounded">
          Your Total Savings on this order <span style={{ color: "rgb(235 10 10)", fontSize: "18px", fontWeight: "600" }}> ₹{total_discount} </span>
        </p>
      </div>
    </>
  );
};
