// import { Link } from "react-router-dom";
import { LandingPageHeader } from "../layouts/landing-page-header";
import { OurServices } from "../containers/landing-page/our-services";
import { TradeMark } from "../containers/landing-page/trade-mark";
import { OurPatners } from "../containers/landing-page/our-patners";
import { Steps } from "../containers/landing-page/steps";
import { Stats } from "../containers/landing-page/stats";
import { AboutUs } from "../containers/landing-page/about-us";
import { ContactUs } from "../containers/landing-page/contact-us";
import { Overview } from "../containers/landing-page/overview";
export const Home = () => {
  return (
    <>
      <LandingPageHeader />
      <div className="card mb-3 border-0">
        <OurServices />
        <TradeMark />
        <Overview />
        <Steps />
        <Stats />
        <AboutUs />
        <OurPatners />

        <ContactUs />
      </div>
    </>
  );
};
