import "./containers.styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  EmptyCart,
  selectedCar,
  UserNo,
} from "../redux/actions/productActions";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../apis/fakeStoreApi";

export const PopUp = (props) => {
  const carSelected = useSelector((state) => state.carSelected.carSelected);
  const [CarSelection, setCarSelection] = useState(carSelected);
  const [SearchWord, setSearchWord] = useState('');
  let history = useHistory();
  const carDataFetch = useSelector((state) => state.carData.carData);

  var jsonCarObj = {};
  carDataFetch.map((item) => {
    const { car_id, car_company, car_model, car_fuel } = item;

    // var year = JSON.parse(car_year);
    var company = JSON.parse(car_company);
    var model = JSON.parse(car_model);
    var fuel = JSON.parse(car_fuel);

    // var yearData = null;
    var companyData = null;
    var modelData = null;
    // var fuelData = null;

    if (jsonCarObj[company.name]) {
      companyData = jsonCarObj[company.name].data;
    }

    if (jsonCarObj[company.name] && jsonCarObj[company.name].data[model.name]) {
      modelData = jsonCarObj[company.name].data[model.name].data;
    }

    jsonCarObj[company.name] = {
      name: company.name,
      img: company.img,
      data: {
        ...companyData,
        [model.name]: {
          name: model.name,
          img: model.img,
          data: {
            ...modelData,
            [fuel.name]: {
              name: fuel.name,
              img: fuel.img,
              data: car_id,
            },
          },
        },
      },
    };

    return <></>;
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (CarSelection.isCarSelected) {
      dispatch(selectedCar(CarSelection));
      dispatch(EmptyCart());
      localStorage.setItem("carSelected", JSON.stringify(CarSelection));

      var url = API_URL + "car_make";
      var formData = new FormData();
      formData.append("car_make", localStorage.getItem("carSelected"));
      if (localStorage.getItem("user_phone")) {
        formData.append("user", localStorage.getItem("user_phone"));
      }
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      axios.post(url, formData, config)
    }
  }, [CarSelection, dispatch]);

  const updateCarData = (name, type, data, img) => {
    if (type === "company") {
      setCarSelection({ ...CarSelection, isCompany: true, Company: name });
    } else if (type === "model") {
      setCarSelection({
        ...CarSelection,
        isModel: true,
        Model: name,
        CarImage: img,
      });
    } else if (type === "fuel") {
      setCarSelection({
        ...CarSelection,
        isFuel: true,
        Fuel: name,
        isYear: true,
        Year: "name",
        isCarSelected: true,
        CarSelected: data,
      });
    } else if (type === "year") {
      setCarSelection({
        ...CarSelection,
        isYear: true,
        Year: name,
        isCarSelected: true,
        CarSelected: data,
      });
      dispatch(selectedCar(CarSelection));
    }
    setSearchWord('');
  };

  const NewCar = () => {
    setCarSelection({
      isYear: false,
      isCompany: false,
      isModel: false,
      isFuel: false,
    });
  };

  const renderList = (Items, type) => {
    // setListRender(Items);
    return Object.keys(Items).filter(name => name.toLowerCase().includes(SearchWord.toLowerCase())).map(function (key) {
      const { name, img, data } = Items[key];
      // const imgurl = 'url('+img+')';
      return (
        <div
          className="col-6 col-md-4 col-lg-4 p-2"
          key={name}
          onClick={() => updateCarData(key, type, data, img)}>
          <div className="card p-2 h-100 d-flex justify-content-center">
            <div className="text-center">
              {/* <div className="back" style={{ backgroundImage:{imgurl}, color:"red" }}>&nsbp</div> */}
              <img className="img-fluid" src={img} alt="not found" width="80" height="50" />
            </div>
            <div className="card-title font-weight-bold">{name}</div>
          </div>
        </div>
      );
    });
  };

  const SearchList = (event) => {
    setSearchWord(event.target.value);
  }

  const phone = useSelector((state) => state.phone.phone);

  const handleEvent = async (e) => {
    var Value = e.target.value;

    const dataPhone = {
      phone: Value,
      isVerified: false,
    };
    if (Value.length === 10) {
      var formData = new FormData();
      const userData = { phone: Value, data: "lead" };
      formData.append("postData", JSON.stringify(userData));
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      var url = API_URL + "signup";
      await axios
        .post(url, formData, config)
        .then((response) => {
          if (response.data === "inserted") {
            dispatch(UserNo(dataPhone));
          }
        })
        .catch((error) => {
          console.log(error);
        });

      localStorage.setItem("user_phone", JSON.stringify(dataPhone));

      url = API_URL + "car_make";
      formData = new FormData();
      formData.append("car_make", localStorage.getItem("carSelected"));
      if (localStorage.getItem("user_phone")) {
        formData.append("user", localStorage.getItem("user_phone"));
      }
      await axios.post(url, formData, config)
    }
  };
  // useEffect(() => {
  //   if (localStorage.getItem("user_phone")) {
  //     setLocalPhone(localStorage.getItem("user_phone"));
  //   }
  // }, []);


  return (
    <div
      className="modal fade customModal"
      id="SelectCar"
      role="dialog"
      aria-hidden="true"
    // style={{ zIndex: 50 }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {CarSelection.isCompany
                ? CarSelection.isModel
                  ? CarSelection.isFuel
                    ? "Picked Car"
                    : "Pick Your Car Fuel"
                  : "Pick Your Car Model"
                : "Pick Your Car Company"}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-center py-0" style={{ maxHeight: 300 }}>
            <div className="mb-1 py-2" style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 99 }} >
              <div style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 99 }}>
                {
                  CarSelection.isFuel ? null :
                    <div className="form-group  has-search">
                      {/* <span className="fa fa-search form-control-feedback"></span> */}
                      <input type="text" className="form-control" id='search_word' placeholder="Search" onChange={(e) => { SearchList(e) }} value={SearchWord} />
                    </div>
                }
              </div>
            </div>



            <div className="row d-flex">
              {CarSelection.isCompany ? (
                CarSelection.isModel ? (
                  CarSelection.isFuel ? (
                    // CarSelection.isYear ? (
                    <div className="w-100 px-3">
                      <div className="row align-items-center">
                        <div className="col-sm-6 col-md-6 col-lg-6">
                          <img
                            alt="car"
                            src={carSelected.CarImage}
                            className="mr-3 rounded-circle img-fluid"
                            style={{ height: 100 }}
                          />
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6">
                          <h4 className="mb-0">
                            {carSelected.Company}
                          </h4>
                          <h6 className="mb-3 text-muted">
                            <i className="feather-map-pin"></i>{" "}
                            {carSelected.Model}, {carSelected.Fuel}
                          </h6>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="phone">Mobile Number : </label>
                        <input
                          type="text"
                          className="form-control"
                          name="phone"
                          id="phone"
                          maxLength="10"
                          defaultValue={phone}
                          onChange={(e) => handleEvent(e)}
                        />
                      </div>
                    </div>
                  ) : (
                    // ) : (
                    //   renderList(
                    //     jsonCarObj[CarSelection.Company].data[
                    //       CarSelection.Model
                    //     ].data[CarSelection.Fuel].data,
                    //     "year"
                    //   )
                    // )
                    renderList(
                      jsonCarObj[CarSelection.Company].data[CarSelection.Model]
                        .data,
                      "fuel"
                    )
                  )
                ) : (
                  renderList(jsonCarObj[CarSelection.Company].data, "model")
                )
              ) : (
                renderList(jsonCarObj, "company")
              )}
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-end">
            {/* <button className="btn btn-outline-danger rounded"
              onClick={() => history.goBack()}
            >Back</button> */}
            <button
              type="button"
              className="btn custom-btn-warning rounded"
              onClick={NewCar}
            // data-dismiss="modal"
            >
              Change Car
            </button>
            {carSelected.isCarSelected && phone.length === 10 ? (
              <button
                type="button"
                onClick={() => {
                  history.push("/product_list");
                }}
                className="btn btn-primary rounded"
                data-dismiss="modal">
                Go to Service
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
