export const PrivacyPolicy = () =>{
    return(
        <section>
            <div className="container mb-4">
                <div className="row d-flex justify-content-center">
                    <h1>
                    Privacy Policy
                    </h1>
                </div>
                <div className="row d-flex">
                    <div className="col-sm-12">
                        <p>
                        This Privacy Policy explains how METRE PER SECOND collects personal and non-personal
                        information, classified as mandatory or voluntary and uses, discloses and protects such
                        information through the mobile application. 'Personal Information is data that can be used to
                        uniquely identify or contact a single person ("Personal Information"). Personal Information for
                        the purposes of this Policy shall include, but not limited to, information regarding Your name,
                        address, mobile number, etc.
                        </p>
                        <p>
                        You may need to provide Your Personal Information during the course of using some services,
for instance, creating a valid User ID, provide information in Your account while registering on
the mobile application, submit data about your car(s), submit your location while availing
METRE PER SECOND services, refer friends, participate in any online survey or contest,
communicate with METRE PER SECOND's customer service by phone, email or otherwise,
provide reviews for the content available on the mobile application.
                        </p>
                        <p>
                        METRE PER SECOND collects only such Personal Information that we believe to be relevant
and is required to understand You or Your interests. METRE PER SECOND collects Your
Personal Information in order to record, support and facilitate Your participation in the activities
You select, track Your preferences, provide You with a customized mobile application
experience, to notify You of any updated information and new activities and other related
functions offered by METRE PER SECOND, keep You informed about latest content available on
the mobile application, special offers, and other products and services of METRE PER SECOND,
to assist You with customer service or technical support issues, to follow up with You after Your
visit, to otherwise support Your relationship with METRE PER SECOND or to prevent fraud and
unlawful use.
                        </p>
                        <p>
                        In an effort to make our website or mobile application effective, certain information may be
collected each time you visit our mobile application which is available on different platforms.
Such information may be stored in server logs. These encrypted statistics do not identify you
personally, but provide us the information regarding your visit to the mobile application and the
type of user who is accessing our mobile application and certain browsing activities by You.
This data may include the IP address of Your server, the type/model/manufacturer of your
mobile, the operating system of Your system, location data. These data are used by METRE
PER SECOND to understand the use and number of users using the mobile application.
                        </p>
                        <p>
                        METRE PER SECOND also uses the log file like other website or mobile application servers
which is the standard measure. They include internet protocol (IP) addresses, browser type,
internet service provider (ISP), referring/exit pages, platform type, date/time stamp, and a
number of clicks. We use them to analyze trends, administer the site, track a user's movement
and gather broad demographic information for aggregate use. IP addresses, etc. are not linked
to personally identifiable information. METRE PER SECOND DOES NOT trade or sell Your
Personal Information in any manner, except as specified herein, or if express consent is sought
from You. Personal Information provided by You is used only:

                        </p>
                        <ul>
                            <li>
                            to facilitate Your use of the mobile application;
                            </li>
                            <li>
                            to respond to Your inquiries or fulfill Your requests for information about the various
services;

                            </li>
                            <li>
                            to provide You with information about METRE PER SECOND's products/services and to
send You information, materials, and offers from METRE PER SECOND;
                            </li>
                            <li>
                            to send You important information regarding the mobile application, changes to METRE
PER SECOND's terms, conditions, and policies and/or other administrative information;
                            </li>
                            <li>
                            to send You surveys and marketing communications that METRE PER SECOND believes
may be of interest to You;

                            </li>
                            <li>
                            to personalize Your experience on the mobile application and METRE PER SECOND's
other websites by presenting advertising, products and offers tailored to You; for proper
administering of the mobile application;
                            </li>
                            <li>
                            to conduct internal reviews and data analysis for the mobile application;
                            </li>
                            <li>
                            to protect the integrity of the mobile application; and
                            </li>
                            <li>
                            to respond to judicial process and provide information to law enforcement agencies or in
connection with an investigation on matters related to public safety, as permitted by
law
                            </li>
                        </ul>
                        <p>
                        METRE PER SECOND may disclose aggregated information about the use of the mobile
application, but it will never contain Personal Information. METRE PER SECOND does not
disclose Personal Information to third parties in the normal course of operations. However, in
situations when METRE PER SECOND is legally obligated to disclose information to the
government or other third parties, METRE PER SECOND will do so
                        </p>
                        <p>
                        METRE PER SECOND implements standard measures to protect against unauthorized access
to and unlawful interception of Personal Information. However, no internet site can fully
eliminate security risks.
                        </p>
                        <p>
                        You have a legal right to a copy of any personal information about you held by us. You also
have a right to correct any errors in that information. As mentioned above, you have a right to
request that we cease to use your personal information for direct marketing purposes. If you
have questions or concerns, feel free to email us support@metrepersecond.com or to
correspond at:
                        </p>
                        <h3>
                        METRE PER SECOND
                        </h3>
                    </div>
                </div>
            </div>
        </section>
    );
}