export const Overview = () => {
  return (
    <section className="feature-car-area white-bg page-section-pt mb-3 mb-mb-3 mb-lg-3" style={{ backgroundColor: "rgb(243 228 241 / 24%)" }}>
      <div className="container">
        <div className="row py-md-3 py-lg-3">
          <div className="col-md-12">
            <div className="section-title">
              <h3 className="font-weight-bold text-dark text-md-center text-md-center mt-2 mt-md-4 mt-lg-4 customModal">
                Company's Overview Car Services
              </h3>
              {/* <h5 className="text-center text-uppercase text-danger">
                Company's Overview Car Services
              </h5> */}
              <p className="text-md-center text-lg-center text-uppercase mb-3">Get reliable car accessories and customized Aftermarket Car Parts</p>
              <div className="mb-4"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="feature-box-2 text-lg-right text-left">
              <div className="icon text-center text-md-right">
                <img src="./assets/img/carRepair.png" className='' width="70px" />
                {/* <i className="fas fa-car-side icons-background p-3" style={{ fontSize: 30 }}></i> */}
              </div>
              <div className="content text-center text-md-right">
                {/* <h5 className="text-danger">Wrapping </h5> */}
                <h5 className="heading-overview mb-0 my-2">Car Repairs</h5>
                <p className="text-muted">
                  Get best Car Repair Service in Hyderabad. All type of Car Repair. Skilled & Experienced In-House Mechanics.
                </p>

              </div>
            </div>
            <div className="feature-box-2 text-lg-right text-left">
              <div className="icon text-center text-md-right">
                <img src="./assets/img/check-up.png" width="70px" />
                {/* <i className="fas fa-palette icons-background p-3" style={{ fontSize: 30 }}></i> */}
              </div>
              <div className="content text-center text-md-right">
                {/* <h5 className="text-danger">Paint Protection Film</h5> */}
                <h5 className="heading-overview mb-0 my-2">Car Inspection</h5>
                <p className="text-muted">We all want our car to look as amazing as it did on the day we bought it.</p>

              </div>
            </div>
            <div className="feature-box-2 text-lg-right text-left">
              <div className="icon text-center text-md-right">
                <img src="./assets/img/spray-paint.png" width="70px" />
                {/* <i className="fas fa-volume-down icons-background p-3 px-4" style={{ fontSize: 30 }}></i> */}
              </div>

              <div className="content text-center text-md-right">
                {/* <h5 className="text-danger">Damping </h5> */}
                <h5 className="heading-overview mb-0 my-2">Car Denting & Painting</h5>
                <p className="text-muted">Preserve your car’s paint with paint protection film and make your car look brand new </p>

              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 car-middle" style={{ backgroundImage: 'url("assets/img/car/18.png")' }}>
            {/* <img className="img-fluid center-block big-car mx-auto d-block" src="assets/img/car/18.png" alt="" /> */}
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="feature-box-2">
              <div className="icon text-center text-md-left">
                <img src="./assets/img/car-major-repair.png" width="70px" />

                {/* <i className="fas fa-pencil-ruler icons-background p-3" style={{ fontSize: 30 }}></i> */}
              </div>
              <div className="content text-center text-md-left">
                {/* <h5 className="text-danger">After Market Accessories </h5> */}
                <h5 className="heading-overview mb-0 my-2">Car Major Repairs</h5>
                <p className="text-muted">Give your car a brand-new look and a bit more stylish with a car body kit.</p>
              </div>
            </div>
            <div className="feature-box-2">
              <div className="icon text-center text-md-left">
                <img src="./assets/img/car-custom-repair.png" width="70px" />
                {/* <i className="far fa-lightbulb icons-background p-3 px-4" style={{ fontSize: 30 }}></i> */}
              </div>
              <div className="content text-center text-md-left">
                {/* <h5 className="text-danger">Lighting Solution</h5> */}
                <h5 className="heading-overview mb-0 my-2">Car Custom Repairs</h5>

                <p className="text-muted">Install a new and quality parts to your car to ensure your safety on roads.</p>
              </div>
            </div>
            <div className="feature-box-2">
              <div className="icon text-center text-md-left">
                <img src="./assets/img/air-conditioner.png" width="70px" />
                {/* <i className="fas fa-car icons-background p-3" style={{ fontSize: 30 }}></i> */}
              </div>

              <div className="content text-center text-md-left">
                {/* <h5 className="text-danger">Nano Ceramic</h5> */}
                <h5 className="heading-overview mb-0 my-2">Car AC Service</h5>
                <p className="text-muted">
                  Certified car AC mechanics conduct regular checks for optimum performance of car air conditioners
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>)
}