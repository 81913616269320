import { Link } from "react-router-dom";
import $ from "jquery";
import { useState } from "react";
import axios from "axios";
import { Verification } from "../containers/verification";
import { useDispatch } from "react-redux";
import { fetchUserIn } from "../redux/actions/productActions";

export const SideSignInComponent = () => {
  const dispatch = useDispatch();
  const [SignInInput, setSignInInput] = useState({});
  const [SignUpInput, setSignUpInput] = useState({
    otp: "123456",
  });

  const SignUpInputOnChange = (e) => {
    var Value = e.target.value;
    var Id = e.target.id;
    setSignUpInput({
      ...SignUpInput,
      [Id]: Value,
    });
  };

  const SignInInputOnChange = (e) => {
    var Value = e.target.value;
    var Id = e.target.id;

    setSignInInput({
      ...SignInInput,
      [Id]: Value,
    });
  };

  const SignUpSubmit = (event) => {
    event.preventDefault();
    var formData = new FormData();
    formData.append("postData", JSON.stringify(SignUpInput));
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    var url = "http://localhost/mps/signup";
    axios
      .post(url, formData, config)
      .then((response) => {

        if (response.data === "inserted") {
          $("#login").removeClass("show");
          $("#login").addClass("hidden");
          $("#verificationModal").addClass("show");
          $("#verificationModal").attr("style", "display: block");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const SignInSubmit = async (event) => {
    event.preventDefault();
    var formData = new FormData();
    formData.append("postData", JSON.stringify(SignInInput));
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    var url = "http://localhost/mps/signin";
    axios
      .post(url, formData, config)
      .then((response) => {

        if (response.data.response === "valid") {
          localStorage.setItem("user", JSON.stringify(response.data.data));
          dispatch(fetchUserIn(response.data.data));
          $(".modal-backdrop").remove();
          $("#login").removeClass("show");
          $("#login").addClass("hidden");
          $("body").removeClass("modal-open");
          $("body").removeAttr("style");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <Verification phone={SignUpInput.phone} />
      <div
        className="modal fade right-modal"
        id="login"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header p-0">
              <nav className="schedule w-100">
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <a
                    className="nav-link active col-5 py-4"
                    id="nav-home-tab"
                    data-toggle="tab"
                    href="#nav-home"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true">
                    <p className="mb-0 font-weight-bold">Sign in</p>
                  </a>
                  <a
                    className="nav-link col-5 py-4"
                    id="nav-profile-tab"
                    data-toggle="tab"
                    href="#nav-profile"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false">
                    <p className="mb-0 font-weight-bold">Sign up</p>
                  </a>
                  <Link
                    className="nav-link col-2 p-0 d-flex align-items-center justify-content-center"
                    // data-dismiss="modal"
                    onClick={() => {
                      $(".modal-backdrop").remove();
                      $("#login").removeClass("show");
                      $("#login").addClass("hidden");
                    }}
                    aria-label="Close"
                    to="#">
                    <h1 className="m-0 h4 text-dark">
                      <i className="icofont-close-line"></i>
                    </h1>
                  </Link>
                </div>
              </nav>
            </div>
            <div className="modal-body p-0">
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab">
                  <div className="osahan-signin p-4 rounded">
                    <div className="p-2">
                      <h2 className="my-0">Welcome Back</h2>
                      <p className="small mb-4">Sign in to Continue.</p>
                      <form onSubmit={SignInSubmit}>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Number</label>
                          <input
                            placeholder="Enter Phone No."
                            type="number"
                            className="form-control"
                            id="signin-phone"
                            aria-describedby="emailHelp"
                            onChange={(e) => {
                              SignInInputOnChange(e);
                            }}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Password
                          </label>
                          <input
                            placeholder="Enter Password"
                            type="password"
                            className="form-control"
                            id="signin-password"
                            onChange={(e) => {
                              SignInInputOnChange(e);
                            }}
                            required
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-success btn-lg rounded btn-block">
                          Sign in
                        </button>
                      </form>
                      {/* <p className="text-center mt-3 mb-0">
                        <a href="#nav-profile" className="text-dark">
                          Don't have an account? Sign up
                        </a>
                      </p> */}
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab">
                  <div className="osahan-signup bg-white p-4">
                    <div className="p-2">
                      <h2 className="my-0">Let's get started</h2>
                      <p className="small mb-4">
                        Create account to see our top picks for you!
                      </p>
                      <form action="" id="myform" onSubmit={SignUpSubmit}>
                        <div className="form-group">
                          <label htmlFor="firstname">Name</label>
                          <input
                            placeholder="Enter Name"
                            type="text"
                            className="form-control"
                            id="firstname"
                            aria-describedby="emailHelp"
                            onChange={(e) => {
                              SignUpInputOnChange(e);
                            }}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="phone">Phone Number</label>
                          <input
                            placeholder="Enter Phone Number"
                            type="number"
                            className="form-control"
                            id="phone"
                            aria-describedby="emailHelp"
                            onChange={(e) => {
                              SignUpInputOnChange(e);
                            }}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <input
                            placeholder="Enter Email"
                            type="email"
                            className="form-control"
                            id="email"
                            aria-describedby="emailHelp"
                            onChange={(e) => {
                              SignUpInputOnChange(e);
                            }}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="password">Password</label>
                          <input
                            placeholder="Enter Password"
                            type="password"
                            className="form-control"
                            id="password"
                            onChange={(e) => {
                              SignUpInputOnChange(e);
                            }}
                            required
                          />
                        </div>
                        {/* <div className="form-group">
                          <label htmlFor="address_1">Address Lane 1*</label>
                          <input
                            placeholder="Address Lane 1*"
                            type="text"
                            className="form-control"
                            id="address_1"
                            onChange={(e) => {
                              SignUpInputOnChange(e);
                            }}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="address_2">Address Lane 2</label>
                          <input
                            placeholder="Address Lane 2"
                            type="text"
                            className="form-control"
                            id="address_2"
                            onChange={(e) => {
                              SignUpInputOnChange(e);
                            }}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="city">City</label>
                          <input
                            placeholder="Enter City"
                            type="text"
                            className="form-control"
                            id="city"
                            onChange={(e) => {
                              SignUpInputOnChange(e);
                            }}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="state">State</label>
                          <input
                            placeholder="Enter State"
                            type="text"
                            className="form-control"
                            id="state"
                            onChange={(e) => {
                              SignUpInputOnChange(e);
                            }}
                            required
                          />
                        </div> */}
                        <button
                          type="submit"
                          className="btn btn-success rounded btn-lg btn-block">
                          Create Account
                        </button>
                      </form>
                      {/* <p className="text-center mt-3 mb-0">
                        <a href="signin.html" className="text-dark">
                          Already have an account! Sign in
                        </a>
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer p-0 border-0">
              <div className="col-6 m-0 p-0">
                <Link
                  to="#"
                  className="btn border-top border-right btn-lg btn-block"
                  // data-dismiss="modal"
                  onClick={() => {
                    $(".modal-backdrop").remove();
                    $("#login").removeClass("show");
                    $("#login").addClass("hidden");
                    $("body").removeClass("modal-open");
                    $("body").removeAttr("style");
                  }}>
                  Close
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
