import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const ServiceType = (props) => {
  const CartItems = useSelector((state) => state.cartItems.cartProducts);

  const [ShowSchedule, setShowSchedule] = useState(true);

  useEffect(()=>{
    CartItems.forEach(elements => {
      if (elements.service_type.indexOf("doorstep") === -1) {
        setShowSchedule(false);
        return;
      }
    })
  }, [CartItems, ShowSchedule])


  return (
    <>
      <div className="bg-white border-0 shadow-sm overflow-hidden mt-1 mb-5 mb-md-0 mb-lg-0 mt-lg-0 mt-md-0">
        <div className="card-header bg-white border-0 p-0" id="headingthree">
          <h2 className="mb-0 d-flex justify-content-between align-items-center">
            <button
              className="btn d-flex align-items-center bg-white btn-block text-left btn-lg h5 px-3 py-4 m-0"
              type="button"
            >
              <span className="c-number">2</span> Service Type
            </button>
            {/* <i className="fas fa-chevron-down mr-4" style={{ fontSize: "15px" }}></i> */}
          </h2>
        </div>
        <div>
          <div className="card-body p-0 border-top">
            <form>
              <div className="osahan-order_address">
                <div className="mx-4 my-3">
                  <h5>Service Type</h5>
                  {ShowSchedule ? <>
                    <div className="form-check">
                      <input
                        className="form-check-input "
                        type="radio"
                        name="selectservice"
                        id="doorstep"
                        value="doorstep"
                        onChange={(e) => {
                          props.onServiceChange(e);

                        }}
                      />
                      <p className="form-check-label" htmlFor="exampleRadios1">
                        Door Step
                        <span className="text-small text-muted font-size-10">
                          &nbsp; (Serviced will be performed at Doorstep)
                        </span>
                        <span>
                          <small className="text-muted ml-3">
                            {/* (*Additionl 500Rs will be charged) */}
                          </small>
                        </span>
                      </p>
                    </div>
                  </> : null}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="selectservice"
                      id="pickupdrop"
                      value="pickupdrop"
                      onChange={(e) => {
                        props.onServiceChange(e);

                      }}
                    />
                    <p className="form-check-label" htmlFor="exampleRadios1">
                      Pick Up and Drop
                      <span className="text-small text-muted font-size-10">
                        &nbsp; (Car will be picked up by Technician, repaired and delivered back to you)
                      </span>
                    </p>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input "
                      type="radio"
                      name="selectservice"
                      id="walkin"
                      value="walkin"
                      onChange={(e) => {
                        props.onServiceChange(e);

                      }}
                    />
                    <p className="form-check-label" htmlFor="exampleRadios1">
                      Walk In
                      <span className="text-small text-muted font-size-10">
                        &nbsp; (you've to come to our Workshop)
                      </span>
                    </p>
                  </div>
                  {/* {showContinue ? (
                    <div className="d-flex justify-content-end">
                      <Link
                        to="#"
                        className="btn btn-success mt-3"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapsetwo"
                        aria-expanded="true"
                        aria-controls="collapsetwo">
                        Continue
                      </Link>
                    </div>
                  ) : null} */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
