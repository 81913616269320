import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchUserIn, UserNo } from "../redux/actions/productActions";
import { API_URL } from "../apis/fakeStoreApi";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;

export const Verification = (props) => {
  const dispatch = useDispatch();
  const [numberValidation, setNumerValidation] = useState()
  const [OTP, setOTP] = useState({
    otp: "",
  });
  const [sendOTP, setsendOTP] = useState(false);
  const [TempPhone, setTempPhone] = useState("");
  // const [InputNumber, setInputNumber] = useState("");

  const UserPhone = useSelector((state) => state.phone);

  const VerifyOtp = (event) => {
    event.preventDefault();

    var formData = new FormData();
    const userData = {
      phone: UserPhone.phone.toString(),
      otp: OTP.otp,
    };

    formData.append("postData", JSON.stringify(userData));

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    var url = API_URL + "verify";
    axios
      .post(url, formData, config)
      .then((response) => {
        if (response.data.response === "valid") {
          const localVal = {
            phone: TempPhone,
            isVerified: true,
          };
          localStorage.setItem("user_phone", JSON.stringify(localVal));
          localStorage.setItem("user", JSON.stringify(response.data.data));
          dispatch(UserNo(localVal));
          dispatch(fetchUserIn(response.data.data));

          $(".modal-backdrop").remove();
          $("#verificationModal").removeClass("show");
          $("#verificationModal").addClass("hidden");
          $("#verificationModal").attr("style", "display: none");
          $("body").removeClass("modal-open");
          $("body").removeAttr("style");

          toast.success('Login successfull');

        } else {

          toast.success('otp successfully ')
          toast.error("Invalid OTP", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // if (UserPhone !== undefined && UserPhone.phone.length === 10) {
    //   setInputNumber(UserPhone.phone);
    // }
    if (UserPhone && UserPhone.isVerified) {
      $(".modal-backdrop").remove();
      $("#verificationModal").removeClass("show");
      $("#verificationModal").addClass("hidden");
      $("#verificationModal").attr("style", "display: none");
      $("body").removeClass("modal-open");
      $("body").removeAttr("style");
    }
  }, [UserPhone]);

  useEffect(() => { }, [sendOTP]);

  const SignUpSubmit = async (SendData) => {
    if (
      (UserPhone !== undefined && UserPhone.phone.length === 10) ||
      TempPhone.length === 10
    ) {
      let sendingPhone = "";
      if (UserPhone !== undefined && UserPhone.phone.length === 10) {
        setTempPhone(UserPhone.phone);
        sendingPhone = UserPhone.phone;
      } else {
        sendingPhone = TempPhone;
      }
      var formData = new FormData();
      const userData = { phone: sendingPhone, data: SendData };
      formData.append("postData", JSON.stringify(userData));
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      var url = API_URL + "signup";
      // const response = await fakeStoreApi.get("/products");
      axios
        .post(url, formData, config)
        .then((response) => {
          if (response.data === "inserted") {
            $("#login").removeClass("show");
            $("#login").addClass("hidden");
            $("#verificationModal").addClass("show");
            $("#verificationModal").attr("style", "display: block");
          }

        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleEvent = (e) => {
    var Value = e.target.value;
    setTempPhone(Value);
    // setInputNumber(Value);
  };

  const SubmitPhone = (e) => {
    // setNumerValidation("#" + validator.trim(e) + "#");

    document.getElementById('number').style.display = "block";

    setInterval(function () {
      if ($('#number').is(':visible')) {
        document.getElementById('number').style.display = "none";
      }
    }, 10000);

    if (/\s/.test(TempPhone)) {
      setNumerValidation('Check mobile number');
      return;
    }

    if (UserPhone && UserPhone.phone.length === 10) {
      setTempPhone(UserPhone);
      const dataPhone = {
        phone: UserPhone.phone,
        isVerified: false,
      };

      dispatch(UserNo(dataPhone));
      SignUpSubmit("buy");
      setsendOTP(true);
      localStorage.setItem("user_phone", JSON.stringify(dataPhone));
    }
    if (TempPhone.length === 10) {
      const dataPhone = {
        phone: TempPhone,
        isVerified: false,
      };
      dispatch(UserNo(dataPhone));
      SignUpSubmit("buy");
      setsendOTP(true);
      localStorage.setItem("user_phone", JSON.stringify(dataPhone));
    }
  };

  // const [OTPCounter, setOTPCounter] = useState(0);

  // const StopTimer = (x) =>{
  //   clearInterval(x);
  //   setOTPCounter(0);
  // }

  // const OTPtimer = (i) =>{
  //   var x = setInterval(function() {
  //     setOTPCounter(i--);
  //     if (OTPCounter <= 0 || i<=0 ) {
  //       StopTimer(x);
  //     }
  //   }, 1000);
  // }

  return (
    <>
      <div
        className="modal fade verificationModal"
        id="verificationModal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        aria-labelledby="verificationModal"
        data-backdrop="static"
        data-keyboard="false">
        <div className="modal-dialog" role="document">
          <section
            className="osahan-main-body modal-content"
            style={{
              width: "100%",
              backgroundColor: "unset",
              border: "unset",
            }}>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-lg-12">
                  <div className="osahan-verification shadow bg-white p-4 rounded">
                    <div className="osahan-form p-3 text-center mt-3">
                      {UserPhone && sendOTP && UserPhone.phone.length === 10 ? (
                        <>
                          <h5>Verify your number</h5>
                          <p className="mb-2" style={{ color: "#000" }}>{UserPhone.phone}</p>
                          <p>Enter the 4-digit code we sent to you</p>
                          <p>{props.phone} </p>
                          {/* {OTPCounter <= 0 ? StopTimer() : null} */}
                          {/* {OTPCounter} sec */}
                          <form action="#" onSubmit={VerifyOtp}>
                            <div className="row my-2">
                              <div className="col px-2">
                                <input
                                  onChange={(e) => {
                                    setOTP({
                                      phone: UserPhone,
                                      otp: e.target.value,
                                    });
                                  }}
                                  type="text"
                                  placeholder="0"
                                  className="form-control opt form-control-lg text-center"
                                  maxLength="4"
                                />
                              </div>
                            </div>
                            {/* <p className="badge badge-danger">{numberValidation}</p> */}
                            <p>
                              <Link
                                to="#"
                                className="text-decoration-none text-success"
                                onClick={() => {
                                  setsendOTP(false);
                                }}>
                                Change Number
                              </Link>
                            </p>
                            <button
                              className="btn btn-success btn-block btn-lg p-2"
                              type="submit">
                              Continue
                            </button>
                          </form>
                        </>
                      ) : (
                        <>
                          <h4 className="mb-3">Enter Mobile No.</h4>
                          <p id="number" className="badge badge-danger" style={{ display: "none" }}>{numberValidation}</p>
                          <div className="row d-flex justify-content-around align-items-center">
                            <div className="col-sm-7 my-2 px-0">
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                name="phone"
                                id="idphone"
                                maxLength="10"
                                // value={`${InputNumber}`}
                                pattern="[+-]?\d+(?:[.,]\d+)?"
                                onChange={(e) => handleEvent(e)}
                              />
                            </div>
                            <div className="col-sm-4 px-0">

                              <button
                                className="btn btn-success p-2 btn-block"
                                type="submit"
                                onClick={(e) => {
                                  SubmitPhone(e.target.value);
                                  // OTPtimer(120);
                                }}>
                                Send OTP
                              </button>
                            </div>
                            {/* {numberValidation} */}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="modal-footer px-1">
                      <Link
                        to="#"
                        className="p-2 btn btn-outline-danger rounded btn-lg btn-block"
                        data-dismiss="modal"
                        onClick={() => {
                          $(".modal-backdrop").remove();
                          $("#verificationModal").removeClass("show");
                          $("#verificationModal").addClass("hidden");
                          $("#verificationModal").attr(
                            "style",
                            "display: none"
                          );
                        }}>
                        Close
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
