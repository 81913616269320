import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addCart, removeCart } from "../redux/actions/productActions";
import $ from "jquery";
import { API_URL } from "../apis/fakeStoreApi";
import axios from "axios";
export const ProductPopUp = (props) => {
  const {
    product_name,
    product_price,
    product_discount,
    product_img,
    product_description,
    product_include,
    product_sub_category,
  } = props.data;
  var Includes = [];
  if (product_include) {
    Includes = product_include.split(",");
  }
  const CartItems = useSelector((state) => state.cartItems.cartProducts);
  const dispatch = useDispatch();
  const addItemList = (Item) => {
    dispatch(addCart(Item));
  };
  const removeItemList = (Item) => {
    dispatch(removeCart(Item));
  };
  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(CartItems));
    var url = API_URL + "cart_items";
    var formData = new FormData();
    formData.append("CartItems", JSON.stringify(CartItems));
    if(localStorage.getItem("user_phone")){
      formData.append("user", localStorage.getItem("user_phone"));
    }

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios.post(url, formData, config)
    
  }, [CartItems]);

  let show = false;
  const length = CartItems?.find((o) => o.product_id === props.data.product_id);
  if (length === undefined) {
    show = true;
  }

  const renderInclude = Includes.map((Item) => {
    return <li key={Item}>{Item}</li>;
  });

  // var JSONObject = [];
  // if (product_sub_category) {
  //   JSONObject = JSON.parse(product_sub_category);
  // }

  const SetSub = (Item) => {
    const { sub_services_title, sub_services_price, id } = Item;
    var data = props.data;
    data.sub_category_title = sub_services_title;
    data.sub_price = parseInt(sub_services_price);
    data.sub_id = parseInt(id);

    dispatch(removeCart(data));
    dispatch(addCart(data));
  };
  const renderSubCategory = product_sub_category?.map((Item, index) => {
    const { sub_services_title, sub_services_price, sub_services_desc } = Item;
    return (
      <label
        className={`btn btn-secondary text-left ${
          index === 0 ? "active" : null
        }`}
        style={{ borderRadius: "10px!important" }}
        key={sub_services_price}
        onClick={() => SetSub(Item)}>
        <h6>{sub_services_title}</h6>
        <p>{sub_services_desc}</p>
        <hr />
        <p>₹{sub_services_price}</p>
        <input
          className=""
          type="radio"
          name="options"
          id="option1"
          hidden
        />{" "}
      </label>
    );
  });
  return (
    <>
      <div
        className="modal fade product-pop-up"
        tabIndex="-1"
        id="product-pop-up"
        role="dialog"
        aria-labelledby="exampleModalLabel">
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Product Details
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  $(".modal-backdrop").remove();
                  $("#product-pop-up").removeClass("show");
                  $("#product-pop-up").addClass("hidden");
                }}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-5">
                  <div className="recommend-slider mb-3">
                    <div className="osahan-slider-item">
                      <img
                        src={product_img}
                        className="img-fluid mx-auto shadow-sm rounded"
                        alt={product_name}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  {/* <div className="pd-f d-flex align-items-center mb-3">
                    <a
                      href="cart.html"
                      className="btn btn-warning p-3 rounded btn-block d-flex align-items-center justify-content-center mr-3 btn-lg"
                    >
                      <i className="icofont-plus m-0 mr-2"></i> ADD TO CART
                    </a>
                    <a
                      href="cart.html"
                      className="btn btn-success p-3 rounded btn-block d-flex align-items-center justify-content-center btn-lg m-0"
                    >
                      <i className="icofont-cart m-0 mr-2"></i> BUY NOW
                    </a>
                  </div> */}
                </div>
                <div className="col-lg-7">
                  <div className="p-4 bg-white rounded shadow-sm">
                    <div className="pt-0">
                      <h4 className="font-weight-bold">{product_name}</h4>
                      <p className="font-weight-light text-dark m-0 d-flex align-items-center">
                        Product MRP :{" "}
                        <b className="h6 text-dark m-0">₹{parseInt(
                        parseInt(product_price) -
                          (parseInt(product_discount) *
                            parseInt(product_price)) /
                            100
                      )}</b>
                        <span className="badge badge-danger ml-2">
                          {product_discount}% OFF
                        </span>
                      </p>
                    </div>
                    <div className="pt-2">
                      <div className="row">
                        <div className="col-6">
                          <p className="font-weight-bold m-0">Delivery</p>
                          <p className="text-muted m-0">Free</p>
                        </div>
                        <div className="col-6 text-right">
                          {show ? (
                            <button
                              data-dismiss="modal"
                              aria-label="Close"
                              className="btn btn-success text-truncate"
                              onClick={() => addItemList(props.data)}>
                              Add to Cart
                            </button>
                          ) : (
                            <button
                              data-dismiss="modal"
                              aria-label="Close"
                              className="btn btn-danger"
                              onClick={() => removeItemList(props.data)}>
                              Remove
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="details">
                      <div className="pt-3 bg-white">
                        <div className="d-flex align-items-center">
                          <div
                            className="btn-group osahan-radio btn-group-toggle"
                            data-toggle="buttons">
                            {product_sub_category ? renderSubCategory : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="details">
                      <div className="pt-3">
                        <p className="font-weight-bold mb-2">
                          What it Includes
                        </p>
                        <p className="text-muted small mb-0">{renderInclude}</p>
                      </div>
                    </div>
                    <div className="details">
                      <div className="pt-3">
                        <p className="font-weight-bold mb-2">Product Details</p>
                        <p className="text-muted small mb-0">
                          {product_description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
